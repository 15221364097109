import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import "../assets/css/contact.css";


export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Message sent successfully' });
    } else {
      setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
    }
  };

  return (
    <section className="contact" id="connect">
      {/* curved display  */}
      <div className="curve">
        <svg viewBox="0 0 500 60">
          <defs>
            <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: 'rgba(170, 54, 124, 0.6) ', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: 'rgba(74, 47, 189, 0.6) ', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <path d="M 0 30 C 150 100 280 0 500 20 L 500 0 L 0 0" fill="url(#grad)"></path>
        </svg>
      </div>
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6} className='avatar'>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 className="heading">Get In Touch</h2>
                  <Row>
                    <Col size={12} sm={6} >
                      <div className="social-icon">
                        <a href="https://github.com/MahmudJewel" target="_blank"><img src="https://img.icons8.com/doodle/50/000000/github--v1.png" alt="github" /></a>
                        <a href="https://www.linkedin.com/in/mahmudjewel" target="_blank"><img src="https://img.icons8.com/doodle/48/null/linkedin-circled.png" /></a>
                        <a href="https://www.hackerrank.com/DJ_cse" target="_blank"><img src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/000000/external-hackerrank-is-a-technology-company-that-focuses-on-competitive-programming-logo-color-tal-revivo.png" /></a>
                        <a href="https://www.youtube.com/channel/UCJCdq7lWqB7M5b16UatoTEw" target="_blank"><img src="https://img.icons8.com/doodle/48/000000/youtube-play--v1.png" /></a>
                        <a href="https://www.facebook.com/Bullet007.0" target="_blank"><img src="https://img.icons8.com/doodle/48/null/facebook-new.png" /></a>
                        <a href="https://www.instagram.com/ek_villain.007" target="_blank"><img src="https://img.icons8.com/doodle/48/000000/instagram-new.png" /></a>
                      </div>
                    </Col>
                  </Row>
                  <br /> <br />
                  <Row className="mb-2">
                    <Col>
                      <img className="contact-icon" src="https://img.icons8.com/doodle/100/null/whatsapp.png" />
                      &nbsp; &nbsp;<span class="contact-text">+880 1916 446 346</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <img className="contact-icon" src="https://img.icons8.com/doodle/96/null/email-sign.png" />
                      &nbsp; &nbsp;<span class="contact-text">mailingtomahmud@gmail.com</span>
                    </Col>
                  </Row>
                  <br />
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        <button type="submit"><span>{buttonText}</span></button>
                      </Col>
                      {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
