// libraries 
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router
} from "react-router-dom";

// css
import '../assets/css/navbar.css';
import { Navbar, Nav, Container } from "react-bootstrap";

// Animation 
import { FlipTextNav } from "./animation/FlipTextNav";




export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container className="main_nav">

          {/* brand  */}
          <Navbar.Brand className="brand" href="/">
            {/* <img src={logo} alt="Mahmud" /> */}
            <h5><FlipTextNav /></h5>
          </Navbar.Brand>

          {/* toggle item  */}
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          {/* nav item  */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto navbar-text">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}><button>Home</button></Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}><button>Skills</button></Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}><button>Projects</button></Nav.Link>
              <Nav.Link href="#connect" className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('connect')}><button>Contact</button></Nav.Link>
            </Nav>
            {/* <span className="navbar-text">
              <HashLink to='#connect'>
                <button className="vvd"><span className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('connect')}>Contact</span></button>
              </HashLink>
            </span> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
