// Libraries

// css 
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "./assets/css/banner.css";
import "./assets/css/skills.css";
import "./assets/css/projects.css";
import "./assets/css/footer.css";
import "./assets/css/animation/animatedripplebg.css";
import "./assets/css/animation/fliptextbanner.css";
import "./assets/css/animation/roundbg.css";
import "./assets/css/animation/butterfly.css";
// import "./assets/css/animation/clock.css";

// components 
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";



function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
