// Libraries 

// css 
import { Container, Row, Col } from "react-bootstrap";

// components 
import { BsFillSuitHeartFill } from "react-icons/bs";


export const Footer = () => {
  let current_year = new Date().getFullYear();
  // let experiences = current_year-2021;
  // console.log('===> Year', sm)
  return (
    <footer className="footer">
      <Container>
        <br /><br /><br />
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            {/* <img src={logo} alt="Logo" /> */}
            <h2><span style={{ color: "aqua" }}>Mahmud</span></h2>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://github.com/MahmudJewel" target="_blank"><img src="https://img.icons8.com/doodle/50/000000/github--v1.png" alt="github" /></a>
              <a href="https://www.linkedin.com/in/mahmudjewel" target="_blank"><img src="https://img.icons8.com/doodle/48/null/linkedin-circled.png" /></a>
              <a href="https://www.hackerrank.com/DJ_cse" target="_blank"><img src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/000000/external-hackerrank-is-a-technology-company-that-focuses-on-competitive-programming-logo-color-tal-revivo.png" /></a>
              <a href="https://www.youtube.com/channel/UCJCdq7lWqB7M5b16UatoTEw" target="_blank"><img src="https://img.icons8.com/doodle/48/000000/youtube-play--v1.png" /></a>
              <a href="https://www.facebook.com/Bullet007.0" target="_blank"><img src="https://img.icons8.com/doodle/48/null/facebook-new.png" /></a>
              <a href="https://www.instagram.com/ek_villain.007" target="_blank"><img src="https://img.icons8.com/doodle/48/000000/instagram-new.png" /></a>
            </div>
            {/* <p>Copyright 2022. All Rights Reserved</p> */}
            <p>Copyright &copy;{current_year} All rights reserved</p>
            <p>This Portfolio is made with <BsFillSuitHeartFill size={30} color="red" /> by <span style={{ color: "aqua" }}>Mahmud</span></p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
