// libraries
import TrackVisibility from "react-on-screen";

// css
import "animate.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

// components 
import { ProjectCard } from "./ProjectCard.jsx";
// import { RoundBg } from "./animation/RoundBg.jsx";

// img 

// json 
import projectsData from "./projects.json";


export const Projects = () => {
  return (
    <section className="project" id="projects">
      {/* <RoundBg /> */}
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    {/* Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. */}
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="django">
                    {/* ********************  */}
                    <Nav variant="pills" className="mb-5">
                      <Nav.Item className="col">
                        <Nav.Link eventKey="django">Django</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col">
                        <Nav.Link eventKey="full-stack">Django+React</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col">
                        <Nav.Link eventKey="react">React</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col">
                        <Nav.Link eventKey="js">JavaScript</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col">
                        <Nav.Link eventKey="bootstrap">Bootstrap</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="col">
                        <Nav.Link eventKey="java">Java</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      {/* first event key ====> Django  */}
                      <Tab.Pane eventKey="django">
                        <Row className="center-item">
                          {projectsData["Django"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                      {/* second event key ==== full-stack */}
                      <Tab.Pane eventKey="full-stack">
                        <Row className="center-item">
                          {projectsData["Full-Stack"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                      {/* Third event key ==== React */}
                      <Tab.Pane eventKey="react">
                        <Row className="center-item">
                          {projectsData["React"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                      {/* Fourth event key ==== javaScript */}
                      <Tab.Pane eventKey="js">
                        <Row className="center-item">
                          {projectsData["JavaScript"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                      {/* Fourth event key ==== bootstrap */}
                      <Tab.Pane eventKey="bootstrap">
                        <Row className="center-item">
                          {projectsData["Bootstrap"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="java">
                        <Row className="center-item">
                          {projectsData["Java"].map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}

                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
